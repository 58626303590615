import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _mjmlCore3 from "mjml-core";

var _mjmlCore2 = "default" in _mjmlCore3 ? _mjmlCore3.default : _mjmlCore3;

import * as _mjmlValidator3 from "mjml-validator";

var _mjmlValidator2 = "default" in _mjmlValidator3 ? _mjmlValidator3.default : _mjmlValidator3;

import * as _mjmlPresetCore3 from "mjml-preset-core";

var _mjmlPresetCore2 = "default" in _mjmlPresetCore3 ? _mjmlPresetCore3.default : _mjmlPresetCore3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
var _interopRequireWildcard = _interopRequireWildcard2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mjmlCore = _interopRequireWildcard(_mjmlCore2);

var _mjmlValidator = _mjmlValidator2;

var _mjmlPresetCore = _interopRequireDefault(_mjmlPresetCore2);

(0, _mjmlCore.assignComponents)(_mjmlCore.components, _mjmlPresetCore.default.components);
(0, _mjmlValidator.assignDependencies)(_mjmlValidator.dependencies, _mjmlPresetCore.default.dependencies);
var _default = _mjmlCore.default;
exports.default = _default;
exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;